import Vue from 'vue'
import Accounting from 'accounting'
import store from '~/store'
import router from '~/router'
import i18n from '~/plugins/i18n'
import App from '~/components/App'
import VueMask from 'v-mask'


import '~/plugins'
import '~/components'
Vue.config.productionTip = false

Vue.filter('currency', (value) => {
  return Accounting.formatMoney(value, '.грн', 2, ',', '.', '%v%s')
})
Vue.filter('currencyUsd', (value) => {
  return Accounting.formatMoney(value, '$', 2, ',', '.', '%v%s')
})
Vue.filter('image', (value) => {
  return process.env.VUE_APP_ENDPOINT_URL + value
})
Vue.use(VueMask)
/* eslint-disable no-new */
new Vue({
  i18n,
  store,
  router,
  ...App
})
