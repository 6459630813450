import * as types from '../mutation-types'

// state
export const state = {
  activeTab: 'stages'
}
export const getters = {
  activeTab: state => state.activeTab
}
export const mutations = {
  [types.SET_TAB] ( state, { tab }) {
    console.log(tab)
    state.activeTab = tab
  }
}
export const actions = {
  setTab({ commit }, tab) {
    commit(types.SET_TAB, { tab: tab })
  }
}