// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'
// lang.js
export const SET_LOCALE = 'SET_LOCALE'
// order.js
export const SET_DELIVERIES = 'SET_DELIVERIES'
export const SET_PAYMENTS = 'SET_PAYMENTS'
export const SET_STATUSES = 'SET_STATUSES'
export const SET_ORDER = 'SET_ORDER'
export const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE'
export const SET_DELIVERY_DATA = 'SET_DELIVERY_DATA'
export const SET_LOADING = 'SET_LOADING'
export const SET_ORDER_NUM = 'SET_ORDER_NUM'
export const SET_ORDER_STATUSES = 'SET_ORDER_STATUSES'
export const SET_PAYMENT_STATUSES = 'SET_PAYMENT_STATUSES'
export const SET_PAYMENTS_TO_DELIVERY = 'SET_PAYMENTS_TO_DELIVERY'
export const SET_ORDER_ID = 'SET_ORDER_ID'
export const SET_MSG = 'SET_MSG'
export const SET_STATUS_EDIT = 'SET_STATUS_EDIT'
export const SET_TYPE_OF_ALERT = 'SET_TYPE_OF_ALERT'
export const SET_EDITABLE = 'SET_EDITABLE'
export const SET_EDITABLE_RECIPIENT = 'SET_EDITABLE_RECIPIENT'
export const SET_EDITABLE_DELIVERY = 'SET_EDITABLE_DELIVERY'
export const SET_EDITABLE_DELIVERY_STATUS = 'SET_EDITABLE_DELIVERY_STATUS'
export const SET_EDITABLE_PRODUCTS = 'SET_EDITABLE_PRODUCTS'
export const SET_BTN_ADD_PRODUCT = 'SET_BTN_ADD_PRODUCT'
export const SET_DISABLED_ORDER_STATUS = 'SET_DISABLED_ORDER_STATUS'
// shift.js
export const SAVE_SHIFT = 'SAVE_SHIFT'
export const REMOVE_SHIFT = 'REMOVE_SHIFT'
export const SET_PRODUCTIONS = 'SET_PRODUCTIONS'
export const SET_PARTIES_VALUE = 'SET_PARTIES_VALUE'
export const SET_PROCESS = 'SET_PROCESS'
// register.js
export const SET_REGISTER_REF = 'SET_REGISTER_REF'
export const SET_REGISTER_TYPE = 'SET_REGISTER_TYPE'
export const SET_REGISTER_DEFAULT_KEY = 'SET_REGISTER_DEFAULT_KEY'
// tab.js
export const SET_TAB = 'SET_TAB'