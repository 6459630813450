import { Base64 } from 'js-base64'

const Order = class {
  constructor (order) {
    this.id = order.id
    this.orderNum = typeof order.order_num !== 'undefined' ? order.order_num : ''
    this.delivery = order.delivery
    this.products = (order.products.length > 0) ? order.products : order.products.data
    this.comment = order.comment
    this.summary = order.summary
    this.deliverySummary = order.deliverySummary
    this.client = order.client
    this.created_at = order.created_at
    this.default_payment = 0
    this.edit = (order && order.edit) ? true : false
  }

  setDeliveryType (val) {
    this.delivery_type = val
  }

  setDeliveryId (deliveries) {
    if (deliveries) {
      const delivery = deliveries.find(
        d => d.slug === this.delivery.delivery_method
      )

      if (typeof delivery !== 'undefined') {
        this.delivery.delivery_method_id = delivery.id
        this.default_payment = delivery.default_payment
      }
    }
  }

  setProductQuantity (qty, productId) {
    const product = this.products.find(p => p.id === productId)
    product.quantity = qty

    return this
  }

  setClientProductPrice (price, productId, characteristicId) {
    const product = this.products.find(p => p.id === productId)
    if ((product.characteristics || []).length > 0) {
      const characteristic = product.characteristics.find(
        c => c.id === characteristicId
      )
      if (typeof characteristic !== 'undefined') {
        characteristic.client_price = price / product.quantity
      }
    } else {
      product.client_price = price / product.quantity
    }

    return this
  }

  setPaymentMethod (e) {
    this.deliverySummary.paymentMethod = e.target.value
    // console.log(this.deliverySummary)
  }

  getTotalPrice () {    
    // const dCost = (this.deliverySummary.delivery_data) ? Number.parseFloat(this.deliverySummary.delivery_data.delivery_cost) : 0
    const dCost = Number.parseInt(this.summary.delivery_cost)
    const deposit = typeof this.deliverySummary.deposit !== 'undefined' ? Number.parseInt(this.deliverySummary.deposit) : 0
    const prices = { delivery_cost: dCost, total: 0, total_drop: 0, deposit_total: 0 }
    /*
    this.products.forEach(p => {
      prices.total += Number.parseFloat(p.total_price)
      prices.total_drop += Number.parseFloat(p.total_price_drop)
    })
    */
    const pr = this.updateProductPrice()
    prices.total = pr.total
    prices.total_drop = pr.total_drop
    // minus deposit
    const depositPaymentStatus = typeof this.deliverySummary.depositPaymentStatus !== 'undefined' ? this.deliverySummary.depositPaymentStatus : null
    if (this.deliverySummary.depositPaymentType === '') {
      prices.deposit_total = prices.total
    } else {
      if (depositPaymentStatus === 'paid') {
        prices.deposit_total = prices.total - deposit
      } else if (depositPaymentStatus === 'unpaid') {
        prices.deposit_total = prices.total
      } else {
        prices.deposit_total = prices.total
      }
    }
    /*
    if (deposit > 0) {
      prices.deposit_total = prices.total - deposit
    } else {
      prices.deposit_total = prices.total
    }
    */
    this.summary = prices
    return prices
  }

  getDeliveryMethodId () {
    return this.delivery.delivery_method_id
  }

  getPaymentMethodId () {
    return this.deliverySummary.paymentMethod
  }

  getDeliveryMethod () {
    return this.delivery.delivery_method
  }

  updateOrderStatuses (statuses) {
    if (statuses) {
      const delivery_method_id = this.getDeliveryMethodId()
      statuses.forEach(s => {
        if ((s.show_for || []).length > 0) {
          const i = s.show_for.find(i => i === delivery_method_id)
          if (typeof i !== 'undefined') {
            s.visible = true
            return
          }
        }
        s.visible = false
      })
      // set default status to order
      const selected = statuses.find(s => s.selected)
      this.deliverySummary.orderStatus = selected.id
      return statuses.filter(s => s.visible)
    }
  }

  updatePaymentStatuses (statuses) {
    if (statuses) {
      const delivery_method_id = this.getDeliveryMethodId()
      statuses.forEach(s => {
        if ((s.show_for || []).length > 0) {
          const i = s.show_for.find(i => i === delivery_method_id)
          if (typeof i !== 'undefined') {
            s.visible = true
            return
          }
        }
        s.visible = false
      })
      // set default status to order
      const selected = statuses.find(s => s.selected)
      this.deliverySummary.paymentStatus = selected.id
      return statuses.filter(s => s.visible)
    }
  }

  paymentsToDelivery (payments) {
    if (payments) {
      const delivery_method_id = this.getDeliveryMethodId()
      payments.forEach(s => {
        if ((s.show_for || []).length > 0) {
          const i = s.show_for.find(i => i === delivery_method_id)
          if (typeof i !== 'undefined') {
            s.visible = true
            return
          }
        }
        s.visible = false
      })

      // const selected = payments.find(s => s.selected)
      this.deliverySummary.paymentMethod = this.default_payment
      // this.paymentsToPayments(payments)
      return payments.filter(s => s.visible)
    }
  }

  paymentsToPayments (statuses) {
    if (statuses) {
      const payment_method_id = this.getPaymentMethodId()
      statuses.forEach(s => {
        if ((s.show_for_payments || []).length > 0) {
          const i = s.show_for_payments.find(i => i === payment_method_id)
          if (typeof i !== 'undefined') {
            s.visible_payment = true
            return
          }
        }
        s.visible_payment = false
        // s.selected = false;
      })
      const selected = statuses.find(s => s.selected)
      if (this.deliverySummary.paymentStatus === '') {
        this.deliverySummary.paymentStatus = selected.id
      }
      return statuses.filter(s => s.visible_payment)
    }
  }

  updateProductPrice () {
    const prices = { total: 0, total_drop: 0 }
    this.products.forEach(item => {
      const qty = item.quantity
      let priceDrop = 0
      let totalPrice = 0
      if ((item.characteristics || []).length > 0) {
        const characteristic = item.characteristics.find(c => c.id === item.characteristic_id)
        if (typeof characteristic !== 'undefined') {
          // characteristic->price - default price
          // characteristic->price_drop - admin to user price
          // characteristic->proce_dropshipper - user to product price
          if (characteristic.price_dropshipper > 0) {
            if (typeof characteristic.client_price !== 'undefined') {
              priceDrop = characteristic.price_drop * qty
              totalPrice = characteristic.price_dropshipper * qty
            } else {
              priceDrop = characteristic.price_drop * qty
              totalPrice = characteristic.price_dropshipper * qty
            }
          } else {
            if (characteristic.price_drop > 0) {
              priceDrop = characteristic.price_drop * qty
              totalPrice = characteristic.price_drop * qty
            } else {
              if (characteristic && characteristic.client_price > 0) {
                if (characteristic.price_drop > 0) {
                  totalPrice = characteristic.client_price * qty
                  priceDrop = characteristic.price_drop * qty
                } else {
                  totalPrice = characteristic.client_price * qty
                  priceDrop = characteristic.price * qty
                }
              } else {
                if (characteristic.price_drop > 0) {
                  totalPrice = characteristic.price_drop * qty
                  priceDrop = characteristic.price_drop * qty
                } else {
                  totalPrice = characteristic.price * qty
                  priceDrop = characteristic.price * qty
                }
              }
            }
          }
          item.total_price = this.edit ? item.total_price : totalPrice
          item.total_price_drop = this.edit ? item.total_price_drop : priceDrop
        }
      } else {
        if (item.price_dropshipper > 0) {
          if (typeof item.client_price !== 'undefined') {
            totalPrice = item.client_price * qty
            priceDrop = item.price_drop * qty
          } else {
            totalPrice = item.price_dropshipper * qty
            priceDrop = item.price_drop * qty
          }
        } else {
          if (item && item.client_price > 0) {
            if (item.price_drop > 0) {
              totalPrice = item.client_price * qty
              priceDrop = item.price_drop * qty
            } else {
              totalPrice = item.client_price * qty
              priceDrop = item.price * qty
            }
          } else {
            if (item.price_drop > 0) {
              totalPrice = item.price_drop * qty
              priceDrop = item.price_drop * qty
            } else {
              totalPrice = item.price * qty
              priceDrop = item.price * qty
            }
          }
        }

        item.total_price = this.edit && !item.new ? item.total_price : totalPrice
        item.total_price_drop = this.edit && !item.new ? item.total_price_drop : priceDrop
        item.characteristic_id = ''
      }
      prices.total += this.edit ? item.total_price : totalPrice
      prices.total_drop += this.edit ? item.total_price_drop : priceDrop
    })
    return prices
  }

  setDeliveryCost (data) {
    this.summary.delivery_cost = data.deliveryCost
    this.deliverySummary.delivery_cost = data.deliveryCost
    this.deliverySummary.delivery_data = data.deliveryDate
  }

  saveOrder () {
    return this.orderSummary()
  }

  /**
   * Get order data object
   * @return object
   */
  getOrderData () {
    return {
      id: this.id,
      orderNum: this.orderNum,
      client: this.client,
      delivery: this.delivery,
      products: this.products,
      comment: this.comment,
      deliverySummary: this.deliverySummary,
      summary: this.summary,
      created_at: this.created_at
    }
  }

  orderSummary () {
    const products = this.products.map(p => {
      const result = /* (p.characteristics && p.characteristics.data) ? p.characteristics.data : */ p.characteristics
      if (result) {
        const characteristic = result.find(c => c.id === p.characteristic_id)
        if (typeof characteristic !== 'undefined') {
          return {
            id: p.product_id,
            total_price: p.total_price,
            total_price_drop: p.total_price_drop,
            quantity: p.quantity,
            characteristicId: p.characteristic_id,
            status: p.status,
            characteristic: {
              id: characteristic.id,
              price: characteristic.price,
              price_drop: characteristic.price_drop,
              price_dropshipper: characteristic.price_dropshipper
            },
            stages: p.stages.map(s => {
              return {
                id: s.id,
                stage_id: s.stage_id,
                photo: s.photo,
                photoLayout: (typeof s.photoLayout !== 'undefined') ? s.photoLayout : null,
                photo_layout: (typeof s.photo_layout !== 'undefined') ? s.photo_layout : null,
                status: (typeof s.status !== 'undefined') ? s.status : 'Новий',
                ready: (typeof s.ready !== 'undefined') ? s.ready : 0
              }
            })
          }
        } else {
          return {
            id: p.product_id,
            total_price: p.total_price,
            total_price_drop: p.total_price_drop,
            quantity: p.quantity,
            status: p.status
          }
        }
      }
    })
    /*
    console.log({
      id: this.id,
      client: this.client,
      delivery: this.delivery,
      products: products,
      comment: this.comment,
      deliverySummary: this.deliverySummary,
      deposit: this.deposit,
      summary: this.summary
    })
    */
    const data = JSON.stringify({
      id: this.id,
      client: this.client,
      delivery: this.delivery,
      products: products,
      comment: this.comment,
      deliverySummary: this.deliverySummary,
      deposit: this.deposit,
      summary: this.summary
    })
    return { order: Base64.encode(data) }
  }
}
export default Order
