<template>
  <div class="messages-layout">
    <div class="container-fluid mt-2">
      <child />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessagesLayout'
}
</script>
