<template>
<b-navbar v-if="user" sticky type="dark" variant="primary" >
    <b-navbar-brand href="/" class="logo">{{ $t('app_name') }}</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <span v-for="(nav, index) in navbar" :key="index">
          <b-nav-item-dropdown v-if="nav.childrens">
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              {{ nav.title }}
            </template>
            <b-dropdown-item :to="{ name: child.name, params: { id: child.id } }" v-for="(child, idx) in nav.childrens" :key="idx">{{ child.title }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-else :to="{ name: nav.name }">{{ nav.title }}</b-nav-item>
        </span>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav v-if="user.role === 'worker'" class="ml-auto">
        <span v-if="shift !== null">
          <b-badge variant="primary">
            {{ $t('shift') }} {{ shift.created_at }}
          </b-badge>
          <b-button variant="danger" size="sm" @click="closeShift">{{ $t('close_shift') }}</b-button>
          <!--<b-badge variant="info">{{ shift.process }}</b-badge>-->
          <!--<b-form-select v-if="productions" v-model="shift.process_id" :options="productions" size="sm" @change="setProductionProcess($event, shift.id)"></b-form-select>-->
        </span>
        <b-button v-if="!shift" variant="success" size="sm" @click="checkingShift($event.target)">{{ $t('open_renovate_shift') }}</b-button>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto notification">
        <!--<b-nav-item :to="{ name: 'notification' }" class="btn btn-lg">
          <fa icon="envelope" />
          <span class="counter counter-lg vis">9</span>
        </b-nav-item>-->
      </b-navbar-nav>
      <b-navbar-nav>
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            <!--<b-img-lazy v-if="user.avatar" :src="user.avatar" rounded="circle" :width="40"></b-img-lazy>-->
            {{ user.first_name }}
          </template>
          <b-dropdown-item :to="{ name: 'profile' }">{{ $t('profile') }}</b-dropdown-item>
          <b-dropdown-item href="#" @click.prevent="logout">{{ $t('logout') }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
    <b-modal ref="openShiftModal" :title="shiftsModal.title" id="shifts-modal" @ok="renovateShift">
      <div v-if="shiftsModal.content.length > 0">
        <h6>Виберіть зміну для відновлення:</h6>
        <ul class="list-unstyled">
          <li v-for="item in shiftsModal.content" :key="item.id">
            <b-form-radio v-model="shiftRadioSelected" :value="item.id">{{ item.created_at }}</b-form-radio>
          </li>
        </ul>
      </div>
      <b-alert v-else show>Для продовження змін не знайдено, ви можете створити нову, нажавши кнопку нижче.</b-alert>
      <b-button size="sm" variant="dark" @click="openNewShift">Створити нову зміну</b-button>
    </b-modal>
</b-navbar>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import Swal from 'sweetalert2'
export default {
data () {
  return {
      shiftsModal: {
        id: 'shifts-modal',
        title: 'Створити нову зміну або продовжити вибрану',
        content: ''
      },
      shiftRadioSelected: null,
      navigation: [
        // admin
        { name: 'orders', title: this.$t('orders'), role: 'admin', type: 'orders' },
        { name: 'products', title: this.$t('products'), role: 'admin', type: 'products' },
        { name: 'specification', title: this.$t('specification'), role: 'admin', type: 'specification' },
        {
          name: '#',
          title: this.$t('operation'),
          type: 'operation',
          childrens: [
            { name: 'operation.settings', title: this.$t('settings') },
            { name: 'operation.counterparties', title: this.$t('counterparties') },
            { name: 'operation.finance', title: this.$t('finance') },
            { name: 'operation.cash.registers', title: this.$t('cash_registers') }
          ],
          role: 'admin'
        },
        {
          name: '#',
          title: this.$t('settings'),
          type: 'settings',
          childrens: [
            { name: 'deliveries', title: this.$t('delivery_type') },
            { name: 'payments', title: this.$t('payment_type') },
            { name: 'statuses', title: this.$t('statuses') },
            { name: 'relationStatuses', title: this.$t('relation_statuses') },
            { name: 'users', title: this.$t('users') },
            { name: 'roles', title: this.$t('roles') },
            { name: 'permissions', title: this.$t('permission') },
            { name: 'checkbox', title: this.$t('checkbox') }
          ],
          role: 'admin'
        },
        // manager
        { name: 'orders', title: this.$t('orders'), role: 'manager', type: 'orders' },
        { name: 'storage', title: this.$t('storage'), role: 'manager', type: 'storage' },
        { name: 'customers', title: this.$t('customers'), role: 'manager', type: 'customers' },
        { name: 'customerGroups', title: this.$t('customer_groups'), role: 'manager', type: 'customerGroups' },
        // dropshipper
        { name: 'orders', title: this.$t('orders'), role: 'dropshipper', type: 'orders' },
        { name: 'storage', title: this.$t('storage'), role: 'dropshipper', type: 'storage' },
        { name: 'customers', title: this.$t('customers'), role: 'dropshipper', type: 'customers' },
        { name: 'customerGroups', title: this.$t('customer_groups'), role: 'dropshipper', type: 'customerGroups' },
        // worker
        { name: 'tasks', title: this.$t('tasks'), role: 'worker', type: 'tasks' },
        { name: 'production', title: this.$t('work'), role: 'worker', type: 'production' },
        { name: 'parties', title: this.$t('parties'), role: 'worker', type: 'parties' },
        { name: 'packaging', title: this.$t('packaging'), role: 'worker', type: 'packaging' },
        {
          name: '',
          title: this.$t('settings'),
          type: 'settings',
          childrens: [
            { name: 'production-process', title: this.$t('process') }
            // { name: 'worker.settings', title: this.$t('settings') }
          ],
          role: 'worker'
        }
      ]
    }
  },
  mounted () {
    this.fetchShift()
    this.getOperationParts()
    // this.fetchProductions()
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      shift: 'shift/shift',
      productions: 'shift/productions'
    }),
    navbar () {
      const data = this.navigation.filter(n => n.role === this.user.role)
      return data
    }
  },
  methods: {
    ...mapActions({
      closeShift: 'shift/closeShift'
    }),
    async checkingShift (btn) {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'shift/check').then(resp => {
          if (resp.data && resp.data.status) {
            this.shiftsModal.content = resp.data.shifts
          }
      })
      this.$root.$refs.loading.finish()
      this.$root.$emit('bv::show::modal', this.shiftsModal.id, btn)
    },
    async openNewShift () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'shift/new').then(resp => {
        if (resp.data && resp.data.status) {
          Swal.fire({
            title: 'Інформація!',
            text: 'Зміну створено!',
            icon: 'info',
            confirmButtonText: 'Ok'
          })
          window.location.reload()
        }
      })
    },
    async renovateShift () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'shift/renovate', { id: this.shiftRadioSelected }).then(resp => {
        if (resp.data) {
          window.location.reload()
          Swal.fire({
              title: 'Інформація!',
              text: 'Зміну поновлено!',
              icon: 'info',
              confirmButtonText: 'Ok'
            })
        }
      })
    },
    async logout () {
      // Log out the user.
      await this.$store.dispatch('auth/logout')
      // Redirect to login.
      this.$router.push({ name: 'login' })
    },
    async fetchShift () {
      const shift = this.shift === 'true'
      if (typeof this.shift === 'undefined' || !shift) {
        await this.$store.dispatch('shift/fetchWorkerShift')
      }
    },
    fetchProductions () {
      if (typeof productions === 'undefined') {
        this.$store.dispatch('shift/fetchProductions')
      }
    },
    async setProductionProcess (processId, shiftId) {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.post(apiUrl + 'shift/process', { id: shiftId, processId: processId })
      this.$store.dispatch('shift/fetchWorkerShift')
    },
    async getOperationParts () {
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      await axios.get(apiUrl + 'operations/parts').then(resp => {
        if (resp.data) {
          const operation = this.navigation.find(n => n.type === 'operation')
          if (typeof operation !== 'undefined') {
            resp.data.parts.forEach(i => {
              operation.childrens.push({
                id: i.id,
                name: 'operation.parts',
                title: i.name
              })
            })
          }
        }
      })
    }
  }
}
</script>
<style>
.bg-light {
  background-color: #fff!important;
}
.logo {
  font-weight: 900;
  color: #ef2a3d;
  border-right: 1px solid rgba(0, 0, 0, 0.075);
  padding-right: 15px;
  text-transform: uppercase;
}
.notification .counter.counter-lg {
  color: #fff;
  background: #ef2a3d;
  position: relative;
  top: -7px;
  left: -8px;
  font-size: 0.65rem;
  border-radius: 50%;
  padding: 0.1rem 0.4rem;
}
</style>
