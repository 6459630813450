function page (path) {
    return () => import(/* webpackChunkName: 'pages' */ `~/pages/${path}`).then(m => m.default || m)
}
export default [
    // Welcome
    // { path: '/', component: page('welcome.vue') },
    // Auth
    { path: '/', name: 'login', component: page('auth/login.vue') },
    { path: '/register', name: 'register', component: page('auth/register.vue') },
    { path: '/password/reset', name: 'password.request', component: page('auth/password/email.vue') },
    { path: '/password/reset/:token', name: 'password.reset', component: page('auth/password/reset.vue') },
    // { path: '/email/verify/:id', name: 'verification.verify', component: page('auth/verification/verify.vue') },
    { path: '/email/verify', name: 'verification.verify', component: page('auth/verification/verify.vue') },
    // { path: '/email/resend', name: 'verification.resend', component: page('auth/verification/resend.vue') },
    // Dashboard
    { path: '/dashboard', name: 'dashboard', component: page('dashboard.vue') },
    // Profile
    { path: '/profile', name: 'profile', component: page('profile/index.vue') },
    // Users
    { path: '/users', name: 'users', component: page('users/index.vue') },
    { path: '/users/create', name: 'users.create', component: page('users/create.vue') },
    { path: '/users/edit/:id', name: 'users.edit', component: page('users/edit.vue') },
    // Roles
    { path: '/roles', name: 'roles', component: page('roles/index.vue') },
    { path: '/roles/create', name: 'roles.create', component: page('roles/create.vue') },
    { path: '/roles/edit/:id', name: 'roles.edit', component: page('roles/edit.vue') },
    // Permission
    { path: '/permissions', name: 'permissions', component: page('permissions/index.vue') },
    { path: '/permissions/create', name: 'permissions.create', component: page('permissions/create.vue') },
    { path: '/permissions/edit/:id', name: 'permissions.edit', component: page('permissions/edit.vue') },
    // Delivery
    { path: '/deliveries', name: 'deliveries', component: page('delivery/index.vue') },
    { path: '/deliveries/create', name: 'deliveries.create', component: page('delivery/create.vue') },
    { path: '/deliveries/edit/:id', name: 'deliveries.edit', component: page('delivery/edit.vue') },
    { path: '/deliveries/statuses', name: 'deliveries.statuses', component: page('delivery/statuses/index.vue') },
    // Payments
    { path: '/payments', name: 'payments', component: page('payment/index.vue') },
    { path: '/payments/create', name: 'payments.create', component: page('payment/create.vue') },
    { path: '/payments/edit/:id', name: 'payments.edit', component: page('payment/edit.vue') },
    // Statuses
    { path: '/statuses', name: 'statuses', component: page('statuses/index.vue') },
    { path: '/statuses/create', name: 'statuses.create', component: page('statuses/create.vue') },
    { path: '/statuses/edit/:id', name: 'statuses.edit', component: page('statuses/edit.vue') },
    // relation statuses
    { path: '/relation-statuses', name: 'relationStatuses', component: page('relationStatuses/index.vue') },
    // Product
    { path: '/products', name: 'products', component: page('product/index.vue') },
    { path: '/products/create', name: 'products.create', component: page('product/create.vue') },
    { path: '/products/edit/:id', name: 'products.edit', component: page('product/edit.vue') },
    // Orders
    { path: '/orders', name: 'orders', component: page('orders/index.vue') },
    { path: '/orders/create', name: 'orders.create', component: page('orders/create.vue') },
    { path: '/orders/create/preview', name: 'orders.create.preview', component: page('orders/preview.vue') },
    { path: '/orders/edit/:id', name: 'orders.edit', component: page('orders/edit.vue') },
    // { path: '/order/products/edit/:id', name: 'order.products.edit', component: page('orders/editProducts.vue') },
    // customer & groups
    { path: '/customers', name: 'customers', component: page('customers/index.vue') },
    { path: '/customers/create', name: 'customers.create', component: page('customers/create.vue') },
    { path: '/customers/edit/:id', name: 'customers.edit', component: page('customers/edit.vue') },
    { path: '/customer-groups', name: 'customerGroups', component: page('customers/group/index.vue') },
    { path: '/customer-groups/create', name: 'customerGroups.create', component: page('customers/group/create.vue') },
    { path: '/customer-groups/edit/:id', name: 'customerGroups.edit', component: page('customers/group/edit.vue') },
    // Work
    { path: '/production', name: 'production', component: page('production/index.vue') },
    { path: '/production-process', name: 'production-process', component: page('process/index.vue') },
    { path: '/production-process/create', name: 'production-process.create', component: page('process/create.vue') },
    { path: '/production-process/edit/:id', name: 'production-process.edit', component: page('process/edit.vue') },
    // Tasks
    { path: '/tasks', name: 'tasks', component: page('tasks/index.vue') },
    // Storage
    { path: '/storage', name: 'storage', component: page('storage/index.vue') },
    { path: '/notification', name: 'notification', component: page('notification/index.vue') },
    // Worker settings
    { path: '/worker', name: 'worker.settings', component: page('worker/index.vue') },
    { path: '/packaging', name: 'packaging', component: page('packaging/index.vue') },
    { path: '/parties', name: 'parties', component: page('parties/index.vue') },
    // Specification
    { path: '/specification', name: 'specification', component: page('specification/index.vue') },
    // checkbox integration
    { path: '/checkbox', name: 'checkbox', component: page('checkbox/index.vue') },
    { path: '/checkbox/create', name: 'checkbox.create', component: page('checkbox/create.vue') },
    { path: '/checkbox/edit/:id', name: 'checkbox.edit', component: page('checkbox/edit.vue') },
    // Operations
  { path: '/operation/settings', name: 'operation.settings', component: page('operation/settings.vue') },
  { path: '/operation/section/:slug', name: 'operation.section', component: page('operation/section.vue') },
  { path: '/operation/types/additional', name: 'operation.types.additional', component: page('operation/types/additional/index.vue') },
  { path: '/operation/parts/:id', name: 'operation.parts', component: page('operation/section.vue') },
  { path: '/operation/counterparties', name: 'operation.counterparties', component: page('operation/counterparties.vue') },
  { path: '/operation/finance', name: 'operation.finance', component: page('operation/finance.vue') },
  { path: '/operation/finance/create', name: 'operation.finance.create', component: page('operation/finance/create.vue') },
  { path: '/operation/finance/:id/:copy', name: 'operation.finance.edit', component: page('operation/finance/edit.vue') },
  { path: '/operation/show/:id', name: 'operation.finance.show', component: page('operation/finance/show.vue') },
  { path: '/operation/cash-registers', name: 'operation.cash.registers', component: page('operation/cashRegister.vue') },
  { path: '/company/:id', name: 'company.info', component: page('operation/company.vue') },
    // errors
    { path: '*', name: '404', component: page('errors/404.vue') }
]
