import Order from '~/lib/order'
import * as types from '../mutation-types'
import axios from 'axios'
import Swal from 'sweetalert2'

const apiUrl = process.env.VUE_APP_ENDPOINT_V1

// state
export const state = {
    deliveries: null,
    payments: null,
    statuses: null,
    deliveryTypes: {
       'nova-posta': [
        { id: 'WarehouseWarehouse', name: 'Склад-Склад' },
        { id: 'WarehouseDoors', name: 'Склад-Двері' }
       ],
       'ukr-posta': {
           types: [
               { id: 'EXPRESS', name: 'Express' },
               { id: 'STANDARD', name: 'Standard' }
           ],
           methods: [
               { id: 'W2D', name: 'Склад-Двері' },
               { id: 'W2W', name: 'Склад-Склад' }
           ]
       }
    },
    
    order: null,
    totalPrice: { total: 0, total_drop: 0 },
    deliveryData: {},
    loading: false,
    orderNum: false,
    orderStatuses: null,
    paymentStatuses: null,
    paymentsToDelivery: null,
    orderId: null,
    msg: '',
    statusEdit: false,
    typeOfAlert: 'danger',
    editOn: false,
    editRecipientData: false,
    editDeliveryData: false,
    editDeliveryStatusData: false,
    editProductsData: false,
    btnAddProductStatus: false,
    setDisabledOrderSelect: false
}
// getters
export const getters = {
  deliveries: state => state.deliveries,
  payments: state => state.payments,
  statuses: state => state.statuses,
  deliveryTypes: state => state.deliveryTypes,
  order: state => {
    if (state.order) {
        return state.order.getOrderData()
    }
  },
  totalPrice: state => state.totalPrice,
  deliveryData: state => state.deliveryData,
  loading: state => state.loading,
  orderNum: state => state.orderNum,
  orderStatuses: state => state.orderStatuses,
  paymentStatuses: state => state.paymentStatuses,
  paymentsToDelivery: state => state.paymentsToDelivery,
  orderSummary: state => {
      if (state.order) {
          return state.order.orderSummary()
      }
  },
  orderId: state => state.orderId,
  msg: state => state.msg,
  statusEdit: state => state.statusEdit,
  typeOfAlert: state => state.typeOfAlert,
  editOn: state => state.editOn,
  printed: state => {
    if (state.order) {
        return state.order.delivery.printed
    }
  },
  editRecipientData: state => state.editRecipientData,
  editDeliveryData: state => state.editDeliveryData,
  editDeliveryStatusData: state => state.editDeliveryStatusData,
  editProductsData: state => state.editProductsData,
  btnAddProductStatus: state => state.btnAddProductStatus,
  setDisabledOrderSelect: state => state.setDisabledOrderSelect
}

// mutations
export const mutations = {
    [types.SET_DELIVERIES] (state, deliveries) {
        state.deliveries = deliveries
    },
    [types.SET_PAYMENTS] (state, payments) {
        state.payments = payments
    },
    [types.SET_STATUSES] (state, statuses) {
        state.statuses = statuses
    },
    [types.SET_ORDER_STATUSES] (state, statuses) {
        state.orderStatuses = statuses
    },
    [types.SET_PAYMENT_STATUSES] (state, statuses) {
        state.paymentStatuses = statuses
    },
    [types.SET_PAYMENTS_TO_DELIVERY] (state, data) {
        state.paymentsToDelivery = data
    },
    [types.SET_ORDER] (state, order) {
        state.order = order
    },
    [types.SET_TOTAL_PRICE] (state, total) {
        state.totalPrice = total
    },
    [types.SET_LOADING] (state, status) {
        state.loading = status
    },
    [types.SET_DELIVERY_DATA] (state, value) {
        state.deliveryData = value
    },
    [types.SET_ORDER_NUM] (state, value) {
        state.orderNum = value
    },
    [types.SET_ORDER_ID] (state, id) {
        state.orderId = id
    },
    [types.SET_MSG] (state, msg) {
        state.msg = msg
    },
    [types.SET_STATUS_EDIT] (state, status) {
        state.statusEdit = status
    },
    [types.SET_EDITABLE] (state, status) {
        state.editOn = status
    },
    [types.SET_TYPE_OF_ALERT] (state, value) {
        state.typeOfAlert = value
    },
    [types.SET_EDITABLE_RECIPIENT] (state, status) {
        state.editRecipientData = status
    },
    [types.SET_EDITABLE_DELIVERY] (state, status) {
        state.editDeliveryData = status
    },
    [types.SET_EDITABLE_DELIVERY_STATUS] (state, status) {
        state.editDeliveryStatusData = status
    },
    [types.SET_EDITABLE_PRODUCTS] (state, status) {
        state.editProductsData = status
    },
    [types.SET_BTN_ADD_PRODUCT] (state, status) {
        state.btnAddProductStatus = status
    },
    [types.SET_DISABLED_ORDER_STATUS] (state, status) {
        state.setDisabledOrderSelect = status
    }
}

// actions
export const actions = {
    async setDeliveries ({ commit, state, getters }) {
        await axios.get(apiUrl + 'deliveries').then(resp => {
            if (resp.data.data.length > 0) {
                commit(types.SET_DELIVERIES, resp.data.data)
                if (state.order) {
                    state.order.setDeliveryId(getters.deliveries)
                }
            }
        })
    },
    async setPayments ({ commit }) {
        const { data } = await axios.get(apiUrl + 'payments')
        const payments = data.data.filter(d => d.status === 1)
        commit(types.SET_PAYMENTS, payments)
    },
    async setStatuses ({ commit }) {
        const { data } = await axios.get(apiUrl + 'statuses')
        commit(types.SET_STATUSES, data.data)
        commit(types.SET_ORDER_STATUSES, data.data.filter(s => s.type === 'order'))
        commit(types.SET_PAYMENT_STATUSES, data.data.filter(s => s.type === 'payment'))
    },
    setOrder ({ commit, state }, order) {
        commit(types.SET_STATUS_EDIT, false)
        commit(types.SET_MSG, null)
        commit(types.SET_TYPE_OF_ALERT, 'danger')
        // commit(types.SET_ORDER, null)
        const orderData = new Order(order)
        commit(types.SET_ORDER, orderData)
        commit(types.SET_LOADING, false)
        commit(types.SET_DELIVERY_DATA, {})
        commit(types.SET_EDITABLE_RECIPIENT, false)
        commit(types.SET_EDITABLE_DELIVERY, false)
        commit(types.SET_EDITABLE_DELIVERY_STATUS, false)
        commit(types.SET_EDITABLE_PRODUCTS, false)
        // calc total price
        state.order.getTotalPrice()
    },
    changeDeliveryType ({ state }, val) {
        state.order.setDeliveryType(val.target.value)
    },
    updateTotalPrice ({ state, commit }) {
        commit(types.SET_TOTAL_PRICE, state.order.getTotalPrice())
    },
    changeProductQty ({ state, commit }, { qty, productId }) {
        state.order.setProductQuantity(qty, productId)
        commit(types.SET_TOTAL_PRICE, state.order.getTotalPrice())
    },
    changeTotalClientPrice ({ state, commit }, { price, productId, characteristicId }) {
        state.order.setClientProductPrice(price, productId, characteristicId)
        commit(types.SET_TOTAL_PRICE, state.order.getTotalPrice())
    },
    async saveOrder ({ state, commit }) {
        commit(types.SET_LOADING, true)
        const orderData = state.order.saveOrder()
        // validate before send order data
        // const delivery_method = state.order.getDeliveryMethod()
        const config = { headers: { 'Content-Type': 'multipart/form-data' } }
        await axios.post(apiUrl + 'order/preorder', orderData, config).then(response => {
            if (response.data && response.data.order) {
                commit(types.SET_ORDER, null)
                commit(types.SET_ORDER_NUM, response.data.order)
                // commit(types.SET_ORDER_ID, response.data.orderId)
            } else if (response.data && response.data.orderId) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Помилка формування ТТН',
                    html: 'При формуванні ТТН виникли помилки або замовлення не заповнено коректно!',
                    reverseButtons: true,
                    confirmButtonText: 'Ok',
                    cancelButtonText: 'Cancel'
                })
                // commit(types.SET_ORDER_ID, response.data.orderId)
                commit(types.SET_LOADING, false)
            }
        }).catch(errors => {
            let data = ''
            if (errors.response.data.errors) {
                const e = errors.response.data.errors
                for (const [key, val] of Object.entries(e)) {
                    data += '<p>' + val + '</p>'
                }
            }
            Swal.fire({
                icon: 'warning',
                title: 'Помилка валідації',
                html: data,
                reverseButtons: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel'
            })
            commit(types.SET_ORDER_NUM, false)
            commit(types.SET_LOADING, false)
        })
    },
    setDeliveryData ({ commit, state }, data) {
        state.order.setDeliveryCost(data)
        commit(types.SET_DELIVERY_DATA, data)
    },
    updateStatuses ({ commit, state, getters, dispatch }) {
        state.order.setDeliveryId(getters.deliveries)
        if (getters.statuses !== null) {
            const orderStatuses = getters.statuses.filter(s => s.type === 'order')
            // const paymentStatuses = getters.statuses.filter(s => s.type === 'payment')
            const order = state.order.updateOrderStatuses(orderStatuses)
            // const payment = state.order.updatePaymentStatuses(paymentStatuses)
            commit(types.SET_ORDER_STATUSES, order)
            // commit(types.SET_PAYMENT_STATUSES, payment)
        }
        if (getters.paymentStatuses) {
            const paymentsTo = state.order.paymentsToDelivery(getters.payments)
            commit(types.SET_PAYMENTS_TO_DELIVERY, paymentsTo)
            const payments = state.order.paymentsToPayments(getters.paymentStatuses)
            commit(types.SET_PAYMENT_STATUSES, payments)
        }
        dispatch('setOrderStatusByRelation')
    },
    setPaymentStatuses (context, e) {
        if (context.getters.payments) {
            context.state.order.setPaymentMethod(e)
            const paymentStatuses = context.getters.statuses.filter(s => s.type === 'payment')
            const payments = context.state.order.paymentsToPayments(paymentStatuses)
            context.commit(types.SET_PAYMENT_STATUSES, payments)
            context.dispatch('setOrderStatusByRelation')
        }
    },
    setOrderStatusByRelation ({ commit, state, getters }) {
        const payment = state.order.deliverySummary.payment ? state.order.deliverySummary.payment : state.order.deliverySummary.paymentMethod
        const paymentStatus = state.order.deliverySummary.paymentStatus
        const relation = getters.payments.find(s => s.id === payment)
        commit(types.SET_DISABLED_ORDER_STATUS, false)
        relation.relation_statuses.forEach(r => {
            if (r.p === payment && paymentStatus === r.ps) {
                commit(types.SET_DISABLED_ORDER_STATUS, true)
                state.order.deliverySummary.orderStatus = r.os
            }
        })
        // set order status = new
        if (!state.setDisabledOrderSelect) {
            const orderStatuses = getters.statuses.filter(s => s.type === 'order')
            state.order.deliverySummary.orderStatus = orderStatuses.find(s => s.name === 'Новий').id
        }
    },
    editCheck (context, mutationName) {
        // printed
        const printed = context.state.order.delivery.printed
        if (printed) {
            if (mutationName === 'SET_EDITABLE_RECIPIENT') {
                context.commit(types[mutationName], false)
            } else if (mutationName === 'SET_EDITABLE_DELIVERY') {
                context.commit(types[mutationName], true)
            } else if (mutationName === 'SET_EDITABLE_PRODUCTS') {
                context.commit(types.SET_STATUS_EDIT, true)
                context.commit(types.SET_MSG, 'Товар уже редагувати не можна, ТТН роздруковано')
                context.commit(types[mutationName], false)
            } else {
                context.commit(types.SET_STATUS_EDIT, true)
                context.commit(types.SET_MSG, 'На даному етапі редагувати дані отримувача не можливо. ТТН була роздрукована. Дочекайтеся відправки і спробуйте ще раз.')
                // set false
                context.commit(types[mutationName], false)
            }
        } else {
            if (mutationName === 'SET_EDITABLE_PRODUCTS') {
                if ((context.state.order.products || []).length > 0) {
                    const products = context.state.order.products.filter(p => {
                        if (p.status !== 'Новий') {
                            p.can_change = false
                        } else {
                            p.can_change = true
                        }
                        return p
                    })
                    if (products.length > 0) {
                        context.commit(types.SET_BTN_ADD_PRODUCT, true)
                    } else {
                        context.commit(types.SET_BTN_ADD_PRODUCT, false)
                    }
                }
            } else {
                context.commit(types[mutationName], true)
            }
        }
    },
    async checkReneval({ state }) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'orders/checkReneval', { ttn: state.order.delivery.internet_document, delivery_method: state.order.delivery.delivery_method }).then(resp => {
            if (resp.data) {
                return resp.data
            }
        })
    },
    async updateOrder ({ state }, {id, type }) {
        // need check 
        const orderData = state.order.orderSummary()
        const order = {
            type: type,
            orderData
        }
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'orders/updateOrder/' + id , order).then(resp => {
            if (resp.data.status) {
                Swal.fire({
                    icon: 'success',
                    title: 'Дані оновлено',
                    text: resp.data.msg,
                    reverseButtons: true,
                    confirmButtonText: 'Ok',
                    cancelButtonText: 'Cancel'
                })
            } else {
                Swal.fire({
                    icon: 'danger',
                    title: 'Помилка оновлення даних',
                    text: resp.data.msg,
                    reverseButtons: true,
                    confirmButtonText: 'Ok',
                    cancelButtonText: 'Cancel'
                })
            }
        })
    },
    async checkDeliveryRedirecting ({ commit }, id) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'orders/checkDeliveryRedirecting/' + id).then(resp => {
            if (resp.data.status) {
                commit(types.SET_EDITABLE_DELIVERY, true)
            } else {
                commit(types.SET_STATUS_EDIT, true)
                commit(types.SET_MSG, resp.data.msg)
            }
        })
    },
    async deliveryRedirecting ({ commit, state }, id) {
        const orderData = state.order.orderSummary()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'orders/deliveryRedirecting/' + id, orderData).then(resp => {
            if (resp.data.status) {
                commit(types.SET_STATUS_EDIT, true)
                commit(types.SET_MSG, resp.data.msg)
                commit(types.SET_TYPE_OF_ALERT, 'success')
            } else {
                commit(types.SET_STATUS_EDIT, true)
                commit(types.SET_MSG, resp.data.msg)
                commit(types.SET_TYPE_OF_ALERT, 'danger')
            }
        })
    },
    async clientEditCheck ({ commit, state }, id) {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'orders/checkChangeEWRecipient/' + id).then(resp => {
            if (resp.data.status) {
                commit(types.SET_EDITABLE_RECIPIENT, true)
            } else {
                commit(types.SET_STATUS_EDIT, true)
                commit(types.SET_MSG, resp.data.msg)
            }
        })
    },
    async saveEWRecipient ({ state }, id) {
        const orderData = state.order.orderSummary()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        await axios.post(apiUrl + 'orders/saveEWRecipient/' + id , orderData).then(resp => {
            if (resp.data.status) {
                commit(types.SET_STATUS_EDIT, true)
                commit(types.SET_MSG, resp.data.msg)
                commit(types.SET_TYPE_OF_ALERT, 'success')
            } else {
                commit(types.SET_STATUS_EDIT, true)
                commit(types.SET_MSG, resp.data.msg)
                commit(types.SET_TYPE_OF_ALERT, 'danger')
            }
        })
    },
    closeEditAlert (context) {
        context.commit(types.SET_STATUS_EDIT, false)
        context.commit(types.SET_MSG, '')
    }
}
